export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 0 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll) {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        const $body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            $body.toggleClass('menu-open')

            if ($body.hasClass('menu-open')) {
                if (window.innerWidth > 1000) {
                    $('[data-trigger="wpiris-menu-submenu"]').first().click()
                }
            } else {
                // Close submenu after 1.5s
                setTimeout(() => {
                    $body.removeClass('submenu-open')
                    $('.submenu').removeClass('active')
                    $('.fullscreen-menu__item').removeClass('active')
                }, 1500)
            }

            $("[data-element='wpiris-menu-submenu']").parent().removeClass('active')

            $('#eco-bar').addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }
}
