import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BannerComponent {
    constructor() {
        BannerComponent.init()
    }

    static async init() {
        const videoContainer = '[data-banner-video]'
        const swiperContainer = '[data-banner-slider]'

        // Show banner video only on desktop
        if ($(videoContainer).length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
            const videoElmt = document.createElement('video')
            const videoSrc = document.createElement('source')

            // config
            videoSrc.type = $(videoContainer).data('banner-video-type')
            videoSrc.src = $(videoContainer).data('banner-video-src')
            videoElmt.muted = true
            videoElmt.loop = true

            videoElmt.appendChild(videoSrc)
            $(videoContainer).append(videoElmt)
            videoElmt.play()
        } else if ($(swiperContainer).length > 0) {
            const options = {
                slidesPerView: 'auto',
                autoplay: {
                    delay: 7500,
                },
                speed: 1000,
                // TODO: trouver pourquoi c'est tout bogué dès qu'on met en fade
                // effect: 'fade',
                navigation: {
                    nextEl: '#swiper-banner-next',
                    prevEl: '#swiper-banner-prev',
                },
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: true,
                loop: true,
            }

            // insert navigation / pagination
            $(`${swiperContainer}> *`)
                .wrapAll('<div class="swiper-wrapper"></div>')
                .addClass('swiper-slide')

            await Swiper.create(swiperContainer, options)
        }
    }
}
