/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import BannerComponent from './components/banner.component'
import MenuComponent from './components/menu.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    // Components always there
    new BannerComponent()
    new NavbarComponent()
    new MenuComponent()

    const alreadyImported = []
    let rellaxInstance

    // Front page only
    if ($('.home').length > 0 && !alreadyImported.includes('HomeService')) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()

            alreadyImported.push('HomeService')
        })
    }

    // Fullscreen template only
    if ($('.fullscreen').length > 0 && !alreadyImported.includes('LayoutService')) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()

            alreadyImported.push('LayoutService')
        })
    }

    // Strate 1 tabs only
    if (
        $('body.home:not(.eco-mode-active) .strate--tabs [data-slider="strate--tabs"]').length > 0
    ) {
        import(
            '@scripts/components/strateTabsSlider.component' /* webpackChunkName: "scripts/strateTabsSlider.component" */
        ).then(({ default: strateTabsSliderComponent }) => {
            new strateTabsSliderComponent()
        })
    }

    // Strate 3 seasons only
    if (
        $('.home:not(.eco-mode-active) .strate--seasons[data-current-tab]').length > 0 &&
        !alreadyImported.includes('StrateSeasonsTabsComponent')
    ) {
        import(
            '@scripts/components/strateSeasonsTabs.component' /* webpackChunkName: "components/strateSeasonsTabs.component" */
        ).then(({ default: StrateSeasonsTabsComponent }) => {
            new StrateSeasonsTabsComponent()

            alreadyImported.push('StrateSeasonsTabsComponent')
        })
    }

    // TODO: reservation widget

    // breakpoint handler
    if (window.innerWidth > 1000) {
        desktopHandler()
    } else {
        mediumHandler()
    }

    // Desktop only imports
    function desktopHandler() {
        // Desktop footer
        if (
            $('.footer__scroll-top').length > 0 &&
            !alreadyImported.includes('FooterScrollTopService')
        ) {
            import(
                '@scripts/services/footerScrollTop.service' /* webpackChunkName: "scripts/footerScrollTop.service" */
            ).then(({ default: FooterScrollTopService }) => {
                new FooterScrollTopService()

                alreadyImported.push('FooterScrollTopService')
            })
        }

        // Desktop home not eco-mode
        if (!$('.home').hasClass('eco-mode-active')) {
            // Desktop Rellax
            if ($('.rellax').length > 0) {
                if (!alreadyImported.includes('Rellax')) {
                    import('rellax' /* webpackChunkName: "scripts/rellax" */).then(
                        ({ default: Rellax }) => {
                            rellaxInstance = new Rellax('.rellax')
                            alreadyImported.push('Rellax')
                        },
                    )
                } else {
                    rellaxInstance.refresh()
                }
            }
        }

        // When we're not on desktop anymore
        window.addEventListener('resize', function mediumOnly() {
            if (window.innerWidth < 1000) {
                if (rellaxInstance) {
                    rellaxInstance.destroy()
                }

                mediumHandler()
                window.removeEventListener('resize', mediumOnly)
            }
        })
    }

    // Medium only imports
    function mediumHandler() {
        let mediumStickySlider

        if ($('body.home:not(.eco-mode-active) .strate--sticky [data-medium-slider]').length > 0) {
            import(
                '@scripts/components/mediumStickySlider.component' /* webpackChunkName: "scripts/mediumStickySlider.component" */
            ).then(({ default: mediumStickySliderComponent }) => {
                mediumStickySlider = new mediumStickySliderComponent()
                mediumStickySlider.swiperInstance.init()
            })
        }

        // When we're not on medium anymore
        window.addEventListener('resize', function desktopOnly() {
            if (window.innerWidth > 1000) {
                if (
                    $('body.home:not(.eco-mode-active) .strate--sticky [data-medium-slider]')
                        .length > 0
                ) {
                    mediumStickySlider.swiperInstance.destroy()
                }

                desktopHandler()
                window.removeEventListener('resize', desktopOnly)
            }
        })
    }
})
